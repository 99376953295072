import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { useTranslations } from '../../../common/hooks/useTranslations';
import { Div } from '../../common/Elements';
import Text from '../../common/Text/Text';
import { Resource } from '../interfaces/resource.interface';
import { ResourceCardCTA } from './ResourceCardCTA';
import { InterpolatedText } from '../../common/InterpolatedText';

const { formatToTimeZone } = require('date-fns-timezone');

interface ResourceCardProps {
  resource: Resource;
  ctaMethod?: (streamId: string) => void;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({ resource, ctaMethod }) => {
  const staticStrings = useStaticStrings();
  const translate = useTranslations();
  return (
    <Div
      paddingHorizontal={[3, 3, 3, 4]}
      heightPercentage={100}
      border="all"
      display="flex"
      flexDirection="column"
      borderColor="gray2"
      paddingVertical={3}
      width="100%"
    >
      <Div marginBottom={3} width="auto" data-testid="resource-type-label">
        <Text
          border="all"
          tag="Span"
          paddingVertical="4px"
          borderColor="black"
          paddingHorizontal={1}
          variant="body2-bold"
        >
          {resource.resourceTypeCustom
            ? translate(resource.resourceTypeCustom)
            : translate(resource.resourceType)}
        </Text>
      </Div>

      {resource.resourceTitle && (
        <Text marginBottom={2} variant="headline5" tag="H3">
          {resource.resourceTitle}
        </Text>
      )}

      {resource.description && (
        <Div
          style={{ height: '160px', overflow: 'hidden' }}
          color="black"
          display="block"
          flex="auto"
          marginBottom={4}
        >
          <ReactMarkdown
            source={resource.description}
            renderers={{
              paragraph: ({ children }) => (
                <Text tag="P" padding={0} margin={0} variant="body2">
                  {children}
                </Text>
              ),
              text: ({ children }) => <InterpolatedText text={children} />,
              strong: ({ children }) => <strong className="fw5">{children}</strong>,
            }}
          />
        </Div>
      )}

      {resource.goodafter && (
        <Text marginBottom={4} marginTop={0} variant="body2-bold" tag="P">
          {`${staticStrings.json_values['date_with_colon']}  ${formatToTimeZone(
            resource.goodafter,
            'M/D/YYYY [@] hh:mm z ',
            {
              timeZone: resource.displaytimezonecd,
            }
          )}`}
        </Text>
      )}
      <ResourceCardCTA resource={resource} ctaMethod={ctaMethod} />
    </Div>
  );
};
