Object.defineProperty(exports, '__esModule', { value: true });

function charCodeToInt(charCode) {
  if (charCode > 96) {
    return charCode - 87;
  } else if (charCode > 64) {
    return charCode - 29;
  }

  return charCode - 48;
}

function unpackBase60(string) {
  var parts = string.split('.');
  var whole = parts[0];
  var fractional = parts[1] || '';
  var multiplier = 1;
  var start = 0;
  var out = 0;
  var sign = 1; // handle negative numbers

  if (string.charCodeAt(0) === 45) {
    start = 1;
    sign = -1;
  } // handle digits before the decimal

  for (var i = start, length = whole.length; i < length; ++i) {
    var num = charCodeToInt(whole.charCodeAt(i));
    out = 60 * out + num;
  } // handle digits after the decimal
  // istanbul ignore next

  for (var _i = 0, _length = fractional.length; _i < _length; ++_i) {
    var _num = charCodeToInt(fractional.charCodeAt(_i));

    multiplier = multiplier / 60;
    out += _num * multiplier;
  }

  return out * sign;
}

function arrayToInt(array) {
  for (var i = 0, length = array.length; i < length; ++i) {
    array[i] = unpackBase60(array[i]);
  }
}

function intToUntil(array, length) {
  for (var i = 0; i < length; ++i) {
    array[i] = Math.round((array[i - 1] || 0) + array[i] * 60000);
  }

  array[length - 1] = Infinity;
}

function mapIndices(source, indices) {
  var out = [];

  for (var i = 0, length = indices.length; i < length; ++i) {
    out[i] = source[indices[i]];
  }

  return out;
}

function unpack(string) {
  var data = string.split('|');
  var offsets = data[2].split(' ');
  var indices = data[3].split('');
  var untils = data[4].split(' ');
  arrayToInt(offsets);
  arrayToInt(indices);
  arrayToInt(untils);
  intToUntil(untils, indices.length);
  var name = data[0];
  var abbreviations = mapIndices(data[1].split(' '), indices);
  var population = data[5] | 0;
  offsets = mapIndices(offsets, indices);
  return {
    name: name,
    abbreviations: abbreviations,
    offsets: offsets,
    untils: untils,
    population: population,
  };
}

var zones;
var names;
var links;
var instances;

function populateTimeZones(_ref) {
  var zoneData = _ref.zones,
    linkData = _ref.links;
  zones = {};
  names = zoneData.map(function(packed) {
    var name = packed.substr(0, packed.indexOf('|'));
    zones[name] = packed;
    return name;
  });
  links = linkData.reduce(function(result, packed) {
    var _packed$split = packed.split('|'),
      name = _packed$split[0],
      alias = _packed$split[1];

    result[alias] = name;
    return result;
  }, {});
  instances = {};
}

function listTimeZones() {
  return names.slice();
}

function findTimeZone(alias) {
  var name = links[alias] || alias;
  var timeZone = instances[name];

  if (!timeZone) {
    var packed = zones[name];

    if (!packed) {
      throw new Error('Unknown time zone "' + name + '".');
    }

    timeZone = instances[name] = unpack(packed);
  }

  return timeZone;
}

function getUnixTimeFromUTC(_ref) {
  var year = _ref.year,
    month = _ref.month,
    day = _ref.day,
    hours = _ref.hours,
    minutes = _ref.minutes,
    _ref$seconds = _ref.seconds,
    seconds = _ref$seconds === void 0 ? 0 : _ref$seconds,
    _ref$milliseconds = _ref.milliseconds,
    milliseconds = _ref$milliseconds === void 0 ? 0 : _ref$milliseconds;
  return Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds);
}

function getDateFromTime(_ref2) {
  var year = _ref2.year,
    month = _ref2.month,
    day = _ref2.day,
    hours = _ref2.hours,
    minutes = _ref2.minutes,
    _ref2$seconds = _ref2.seconds,
    seconds = _ref2$seconds === void 0 ? 0 : _ref2$seconds,
    _ref2$milliseconds = _ref2.milliseconds,
    milliseconds = _ref2$milliseconds === void 0 ? 0 : _ref2$milliseconds;
  return new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);
}

function getUTCTime(date) {
  var year = date.getUTCFullYear();
  var month = date.getUTCMonth() + 1;
  var day = date.getUTCDate();
  var dayOfWeek = date.getUTCDay();
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  var seconds = date.getUTCSeconds() || 0;
  var milliseconds = date.getUTCMilliseconds() || 0;
  return {
    year: year,
    month: month,
    day: day,
    dayOfWeek: dayOfWeek,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    milliseconds: milliseconds,
  };
}

function getLocalTime(date) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var dayOfWeek = date.getDay();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds() || 0;
  var milliseconds = date.getMilliseconds() || 0;
  return {
    year: year,
    month: month,
    day: day,
    dayOfWeek: dayOfWeek,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    milliseconds: milliseconds,
  };
}

function getDateTime(date, options) {
  var _ref3 = options || {},
    useUTC = _ref3.useUTC;

  var extract;

  if (useUTC === true) {
    extract = getUTCTime;
  } else if (useUTC === false) {
    extract = getLocalTime;
  } else {
    throw new Error('Extract local or UTC date? Set useUTC option.');
  }

  return extract(date);
}

function findTransitionIndex(unixTime, timeZone) {
  var untils = timeZone.untils;

  for (var i = 0, length = untils.length; i < length; ++i) {
    if (unixTime < untils[i]) {
      return i;
    }
  }
}

function getTransition(unixTime, timeZone) {
  var transitionIndex = findTransitionIndex(unixTime, timeZone);
  var abbreviation = timeZone.abbreviations[transitionIndex];
  var offset = timeZone.offsets[transitionIndex];
  return {
    abbreviation: abbreviation,
    offset: offset,
  };
}

function attachEpoch(time, unixTime) {
  Object.defineProperty(time, 'epoch', {
    value: unixTime,
  });
}

function getUTCOffset(date, timeZone) {
  var unixTime = typeof date === 'number' ? date : date.getTime();

  var _getTransition = getTransition(unixTime, timeZone),
    abbreviation = _getTransition.abbreviation,
    offset = _getTransition.offset;

  return {
    abbreviation: abbreviation,
    offset: offset,
  };
}

function getZonedTime(date, timeZone) {
  var gotUnixTime = typeof date === 'number';
  var unixTime = gotUnixTime ? date : date.getTime();

  var _getTransition2 = getTransition(unixTime, timeZone),
    abbreviation = _getTransition2.abbreviation,
    offset = _getTransition2.offset;

  if (gotUnixTime || offset) {
    date = new Date(unixTime - offset * 60000);
  }

  var time = getUTCTime(date);
  time.zone = {
    abbreviation: abbreviation,
    offset: offset,
  };
  attachEpoch(time, unixTime);
  return time;
}

function getUnixTime(time, timeZone) {
  var zone = time.zone,
    epoch = time.epoch;

  if (epoch) {
    if (timeZone) {
      throw new Error('Both epoch and other time zone specified. Omit the other one.');
    }

    return epoch;
  }

  var unixTime = getUnixTimeFromUTC(time);

  if (zone) {
    if (timeZone) {
      throw new Error('Both own and other time zones specified. Omit the other one.');
    }
  } else {
    if (!timeZone) {
      throw new Error('Missing other time zone.');
    }

    zone = getTransition(unixTime, timeZone);
  }

  return unixTime + zone.offset * 60000;
}

function setTimeZone(time, timeZone, options) {
  if (time instanceof Date) {
    time = getDateTime(time, options);
  } else {
    var _time = time,
      year = _time.year,
      month = _time.month,
      day = _time.day,
      hours = _time.hours,
      minutes = _time.minutes,
      _time$seconds = _time.seconds,
      seconds = _time$seconds === void 0 ? 0 : _time$seconds,
      _time$milliseconds = _time.milliseconds,
      milliseconds = _time$milliseconds === void 0 ? 0 : _time$milliseconds;
    time = {
      year: year,
      month: month,
      day: day,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      milliseconds: milliseconds,
    };
  }

  var unixTime = getUnixTimeFromUTC(time);
  var utcDate = new Date(unixTime);
  time.dayOfWeek = utcDate.getUTCDay();

  var _getTransition3 = getTransition(unixTime, timeZone),
    abbreviation = _getTransition3.abbreviation,
    offset = _getTransition3.offset;

  time.zone = {
    abbreviation: abbreviation,
    offset: offset,
  };
  attachEpoch(time, unixTime + offset * 60000);
  return time;
}

function convertTimeToDate(time) {
  var epoch = time.epoch;

  if (epoch !== undefined) {
    return new Date(epoch);
  }

  var _ref = time.zone || {},
    offset = _ref.offset;

  if (offset === undefined) {
    return getDateFromTime(time);
  }

  var unixTime = getUnixTimeFromUTC(time);
  return new Date(unixTime + offset * 60000);
}

function convertDateToTime(date) {
  var time = getLocalTime(date);
  var match = /\(([^)]+)\)$/.exec(date.toTimeString());
  time.zone = {
    abbreviation: match
      ? match[1] // istanbul ignore next
      : '???',
    offset: date.getTimezoneOffset(),
  };
  attachEpoch(time, date.getTime());
  return time;
}

var data = {
  version: '2018g',
  zones: [
    'Africa/Abidjan|GMT|0|0||48e5',
    'Africa/Nairobi|EAT|-30|0||47e5',
    'Africa/Algiers|CET|-10|0||26e5',
    'Africa/Lagos|WAT|-10|0||17e6',
    'Africa/Khartoum|CAT|-20|0||51e5',
    'Africa/Cairo|EET|-20|0||15e6',
    'Africa/Casablanca|+01|-10|0||32e5',
    'Europe/Paris|CET CEST|-10 -20|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|11e6',
    'Africa/Johannesburg|SAST|-20|0||84e5',
    'America/Adak|HST HDT|a0 90|0101010101010|22bM0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|326',
    'America/Anchorage|AKST AKDT|90 80|0101010101010|22bL0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|30e4',
    'America/Santo_Domingo|AST|40|0||29e5',
    'America/Fortaleza|-03|30|0||34e5',
    'America/Asuncion|-03 -04|30 40|0101010101010|22hf0 1ip0 19X0 1fB0 19X0 1fB0 19X0 1fB0 19X0 1ip0 17b0 1ip0|28e5',
    'America/Panama|EST|50|0||15e5',
    'America/Mexico_City|CST CDT|60 50|0101010101010|22mU0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|20e6',
    'America/Managua|CST|60|0||22e5',
    'America/Caracas|-04|40|0||29e5',
    'America/Lima|-05|50|0||11e6',
    'America/Denver|MST MDT|70 60|0101010101010|22bJ0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|26e5',
    'America/Campo_Grande|-03 -04|30 40|0101010101010|223f0 1HB0 IL0 1HB0 FX0 1HB0 IL0 1EN0 FX0 1HB0 FX0 1HB0|77e4',
    'America/Chicago|CST CDT|60 50|0101010101010|22bI0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|92e5',
    'America/Chihuahua|MST MDT|70 60|0101010101010|22mV0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|81e4',
    'America/Phoenix|MST|70|0||42e5',
    'America/Los_Angeles|PST PDT|80 70|0101010101010|22bK0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6',
    'America/New_York|EST EDT|50 40|0101010101010|22bH0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e6',
    'America/Halifax|AST ADT|40 30|0101010101010|22bG0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|39e4',
    'America/Godthab|-03 -02|30 20|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|17e3',
    'America/Havana|CST CDT|50 40|0101010101010|22bF0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0|21e5',
    'America/Miquelon|-03 -02|30 20|0101010101010|22bF0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|61e2',
    'America/Noronha|-02|20|0||30e2',
    'America/Santiago|-03 -04|30 40|0101010101010|22mP0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 11B0 1nX0 11B0|62e5',
    'America/Sao_Paulo|-02 -03|20 30|0101010101010|223e0 1HB0 IL0 1HB0 FX0 1HB0 IL0 1EN0 FX0 1HB0 FX0 1HB0|20e6',
    'Atlantic/Azores|-01 +00|10 0|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|25e4',
    'America/St_Johns|NST NDT|3u 2u|0101010101010|22bFu 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|11e4',
    'Asia/Kuala_Lumpur|+08|-80|0||71e5',
    'Asia/Bangkok|+07|-70|0||15e6',
    'Asia/Vladivostok|+10|-a0|0||60e4',
    'Asia/Sakhalin|+11|-b0|0||58e4',
    'Asia/Tashkent|+05|-50|0||23e5',
    'Pacific/Auckland|NZDT NZST|-d0 -c0|0101010101010|22mC0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1io0 1a00 1fA0 1a00|14e5',
    'Europe/Istanbul|+03|-30|0||13e6',
    'Antarctica/Troll|+00 +02|0 -20|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|40',
    'Asia/Dhaka|+06|-60|0||16e6',
    'Asia/Amman|EET EEST|-20 -30|0101010101010|22ja0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0|25e5',
    'Asia/Kamchatka|+12|-c0|0||18e4',
    'Asia/Dubai|+04|-40|0||39e5',
    'Asia/Beirut|EET EEST|-20 -30|0101010101010|22jW0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0|22e5',
    'Asia/Kolkata|IST|-5u|0||15e6',
    'Asia/Chita|+09|-90|0||33e4',
    'Asia/Shanghai|CST|-80|0||23e6',
    'Asia/Colombo|+0530|-5u|0||22e5',
    'Asia/Damascus|EET EEST|-20 -30|0101010101010|22ja0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0|26e5',
    'Europe/Athens|EET EEST|-20 -30|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|35e5',
    'Asia/Gaza|EET EEST|-20 -30|0101010101010|22jz0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0|18e5',
    'Asia/Hong_Kong|HKT|-80|0||73e5',
    'Asia/Jakarta|WIB|-70|0||31e6',
    'Asia/Jayapura|WIT|-90|0||26e4',
    'Asia/Jerusalem|IST IDT|-20 -30|0101010101010|22jc0 1oL0 10N0 1rz0 W10 1rz0 W10 1rz0 10N0 1oL0 10N0 1oL0|81e4',
    'Asia/Kabul|+0430|-4u|0||46e5',
    'Asia/Karachi|PKT|-50|0||24e6',
    'Asia/Kathmandu|+0545|-5J|0||12e5',
    'Asia/Makassar|WITA|-80|0||15e5',
    'Asia/Manila|PST|-80|0||24e6',
    'Asia/Seoul|KST|-90|0||23e6',
    'Asia/Rangoon|+0630|-6u|0||48e5',
    'Asia/Tehran|+0330 +0430|-3u -4u|0101010101010|22gIu 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0|14e6',
    'Asia/Tokyo|JST|-90|0||38e6',
    'Europe/Lisbon|WET WEST|0 -10|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|27e5',
    'Atlantic/Cape_Verde|-01|10|0||50e4',
    'Australia/Sydney|AEDT AEST|-b0 -a0|0101010101010|22mE0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0|40e5',
    'Australia/Adelaide|ACDT ACST|-au -9u|0101010101010|22mEu 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0|11e5',
    'Australia/Brisbane|AEST|-a0|0||20e5',
    'Australia/Darwin|ACST|-9u|0||12e4',
    'Australia/Eucla|+0845|-8J|0||368',
    'Australia/Lord_Howe|+11 +1030|-b0 -au|0101010101010|22mD0 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1fzu 1cMu 1cLu 1cMu|347',
    'Australia/Perth|AWST|-80|0||18e5',
    'Pacific/Easter|-05 -06|50 60|0101010101010|22mP0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 11B0 1nX0 11B0|30e2',
    'Europe/Dublin|GMT IST|0 -10|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|12e5',
    'Pacific/Tongatapu|+13|-d0|0||75e3',
    'Pacific/Kiritimati|+14|-e0|0||51e2',
    'Etc/GMT-2|+02|-20|0|',
    'Pacific/Tahiti|-10|a0|0||18e4',
    'Pacific/Niue|-11|b0|0||12e2',
    'Etc/GMT+12|-12|c0|0|',
    'Pacific/Galapagos|-06|60|0||25e3',
    'Etc/GMT+7|-07|70|0|',
    'Pacific/Pitcairn|-08|80|0||56',
    'Pacific/Gambier|-09|90|0||125',
    'Etc/UCT|UCT|0|0|',
    'Etc/UTC|UTC|0|0|',
    'Europe/London|GMT BST|0 -10|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|10e6',
    'Europe/Chisinau|EET EEST|-20 -30|0101010101010|22k00 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|67e4',
    'Europe/Moscow|MSK|-30|0||16e6',
    'Pacific/Honolulu|HST|a0|0||37e4',
    'MET|MET MEST|-10 -20|0101010101010|22k10 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00',
    'Pacific/Chatham|+1345 +1245|-dJ -cJ|0101010101010|22mC0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1io0 1a00 1fA0 1a00|600',
    'Pacific/Apia|+14 +13|-e0 -d0|0101010101010|22mC0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1io0 1a00 1fA0 1a00|37e3',
    'Pacific/Fiji|+13 +12|-d0 -c0|0101010101010|21PO0 1SM0 uM0 1VA0 s00 1VA0 s00 1VA0 s00 1VA0 uM0 1SM0|88e4',
    'Pacific/Guam|ChST|-a0|0||17e4',
    'Pacific/Marquesas|-0930|9u|0||86e2',
    'Pacific/Pago_Pago|SST|b0|0||37e2',
  ],
  links: [
    'Africa/Abidjan|Africa/Accra',
    'Africa/Abidjan|Africa/Bamako',
    'Africa/Abidjan|Africa/Banjul',
    'Africa/Abidjan|Africa/Bissau',
    'Africa/Abidjan|Africa/Conakry',
    'Africa/Abidjan|Africa/Dakar',
    'Africa/Abidjan|Africa/Freetown',
    'Africa/Abidjan|Africa/Lome',
    'Africa/Abidjan|Africa/Monrovia',
    'Africa/Abidjan|Africa/Nouakchott',
    'Africa/Abidjan|Africa/Ouagadougou',
    'Africa/Abidjan|Africa/Timbuktu',
    'Africa/Abidjan|America/Danmarkshavn',
    'Africa/Abidjan|Atlantic/Reykjavik',
    'Africa/Abidjan|Atlantic/St_Helena',
    'Africa/Abidjan|Etc/GMT',
    'Africa/Abidjan|Etc/GMT+0',
    'Africa/Abidjan|Etc/GMT-0',
    'Africa/Abidjan|Etc/GMT0',
    'Africa/Abidjan|Etc/Greenwich',
    'Africa/Abidjan|GMT',
    'Africa/Abidjan|GMT+0',
    'Africa/Abidjan|GMT-0',
    'Africa/Abidjan|GMT0',
    'Africa/Abidjan|Greenwich',
    'Africa/Abidjan|Iceland',
    'Africa/Algiers|Africa/Tunis',
    'Africa/Cairo|Africa/Tripoli',
    'Africa/Cairo|Egypt',
    'Africa/Cairo|Europe/Kaliningrad',
    'Africa/Cairo|Libya',
    'Africa/Casablanca|Africa/El_Aaiun',
    'Africa/Casablanca|Etc/GMT-1',
    'Africa/Johannesburg|Africa/Maseru',
    'Africa/Johannesburg|Africa/Mbabane',
    'Africa/Khartoum|Africa/Blantyre',
    'Africa/Khartoum|Africa/Bujumbura',
    'Africa/Khartoum|Africa/Gaborone',
    'Africa/Khartoum|Africa/Harare',
    'Africa/Khartoum|Africa/Kigali',
    'Africa/Khartoum|Africa/Lubumbashi',
    'Africa/Khartoum|Africa/Lusaka',
    'Africa/Khartoum|Africa/Maputo',
    'Africa/Khartoum|Africa/Windhoek',
    'Africa/Lagos|Africa/Bangui',
    'Africa/Lagos|Africa/Brazzaville',
    'Africa/Lagos|Africa/Douala',
    'Africa/Lagos|Africa/Kinshasa',
    'Africa/Lagos|Africa/Libreville',
    'Africa/Lagos|Africa/Luanda',
    'Africa/Lagos|Africa/Malabo',
    'Africa/Lagos|Africa/Ndjamena',
    'Africa/Lagos|Africa/Niamey',
    'Africa/Lagos|Africa/Porto-Novo',
    'Africa/Lagos|Africa/Sao_Tome',
    'Africa/Nairobi|Africa/Addis_Ababa',
    'Africa/Nairobi|Africa/Asmara',
    'Africa/Nairobi|Africa/Asmera',
    'Africa/Nairobi|Africa/Dar_es_Salaam',
    'Africa/Nairobi|Africa/Djibouti',
    'Africa/Nairobi|Africa/Juba',
    'Africa/Nairobi|Africa/Kampala',
    'Africa/Nairobi|Africa/Mogadishu',
    'Africa/Nairobi|Indian/Antananarivo',
    'Africa/Nairobi|Indian/Comoro',
    'Africa/Nairobi|Indian/Mayotte',
    'America/Adak|America/Atka',
    'America/Adak|US/Aleutian',
    'America/Anchorage|America/Juneau',
    'America/Anchorage|America/Metlakatla',
    'America/Anchorage|America/Nome',
    'America/Anchorage|America/Sitka',
    'America/Anchorage|America/Yakutat',
    'America/Anchorage|US/Alaska',
    'America/Campo_Grande|America/Cuiaba',
    'America/Caracas|America/Boa_Vista',
    'America/Caracas|America/Guyana',
    'America/Caracas|America/La_Paz',
    'America/Caracas|America/Manaus',
    'America/Caracas|America/Porto_Velho',
    'America/Caracas|Brazil/West',
    'America/Caracas|Etc/GMT+4',
    'America/Chicago|America/Indiana/Knox',
    'America/Chicago|America/Indiana/Tell_City',
    'America/Chicago|America/Knox_IN',
    'America/Chicago|America/Matamoros',
    'America/Chicago|America/Menominee',
    'America/Chicago|America/North_Dakota/Beulah',
    'America/Chicago|America/North_Dakota/Center',
    'America/Chicago|America/North_Dakota/New_Salem',
    'America/Chicago|America/Rainy_River',
    'America/Chicago|America/Rankin_Inlet',
    'America/Chicago|America/Resolute',
    'America/Chicago|America/Winnipeg',
    'America/Chicago|CST6CDT',
    'America/Chicago|Canada/Central',
    'America/Chicago|US/Central',
    'America/Chicago|US/Indiana-Starke',
    'America/Chihuahua|America/Mazatlan',
    'America/Chihuahua|Mexico/BajaSur',
    'America/Denver|America/Boise',
    'America/Denver|America/Cambridge_Bay',
    'America/Denver|America/Edmonton',
    'America/Denver|America/Inuvik',
    'America/Denver|America/Ojinaga',
    'America/Denver|America/Shiprock',
    'America/Denver|America/Yellowknife',
    'America/Denver|Canada/Mountain',
    'America/Denver|MST7MDT',
    'America/Denver|Navajo',
    'America/Denver|US/Mountain',
    'America/Fortaleza|America/Araguaina',
    'America/Fortaleza|America/Argentina/Buenos_Aires',
    'America/Fortaleza|America/Argentina/Catamarca',
    'America/Fortaleza|America/Argentina/ComodRivadavia',
    'America/Fortaleza|America/Argentina/Cordoba',
    'America/Fortaleza|America/Argentina/Jujuy',
    'America/Fortaleza|America/Argentina/La_Rioja',
    'America/Fortaleza|America/Argentina/Mendoza',
    'America/Fortaleza|America/Argentina/Rio_Gallegos',
    'America/Fortaleza|America/Argentina/Salta',
    'America/Fortaleza|America/Argentina/San_Juan',
    'America/Fortaleza|America/Argentina/San_Luis',
    'America/Fortaleza|America/Argentina/Tucuman',
    'America/Fortaleza|America/Argentina/Ushuaia',
    'America/Fortaleza|America/Bahia',
    'America/Fortaleza|America/Belem',
    'America/Fortaleza|America/Buenos_Aires',
    'America/Fortaleza|America/Catamarca',
    'America/Fortaleza|America/Cayenne',
    'America/Fortaleza|America/Cordoba',
    'America/Fortaleza|America/Jujuy',
    'America/Fortaleza|America/Maceio',
    'America/Fortaleza|America/Mendoza',
    'America/Fortaleza|America/Montevideo',
    'America/Fortaleza|America/Paramaribo',
    'America/Fortaleza|America/Punta_Arenas',
    'America/Fortaleza|America/Recife',
    'America/Fortaleza|America/Rosario',
    'America/Fortaleza|America/Santarem',
    'America/Fortaleza|Antarctica/Palmer',
    'America/Fortaleza|Antarctica/Rothera',
    'America/Fortaleza|Atlantic/Stanley',
    'America/Fortaleza|Etc/GMT+3',
    'America/Halifax|America/Glace_Bay',
    'America/Halifax|America/Goose_Bay',
    'America/Halifax|America/Moncton',
    'America/Halifax|America/Thule',
    'America/Halifax|Atlantic/Bermuda',
    'America/Halifax|Canada/Atlantic',
    'America/Havana|Cuba',
    'America/Lima|America/Bogota',
    'America/Lima|America/Eirunepe',
    'America/Lima|America/Guayaquil',
    'America/Lima|America/Porto_Acre',
    'America/Lima|America/Rio_Branco',
    'America/Lima|Brazil/Acre',
    'America/Lima|Etc/GMT+5',
    'America/Los_Angeles|America/Dawson',
    'America/Los_Angeles|America/Ensenada',
    'America/Los_Angeles|America/Santa_Isabel',
    'America/Los_Angeles|America/Tijuana',
    'America/Los_Angeles|America/Vancouver',
    'America/Los_Angeles|America/Whitehorse',
    'America/Los_Angeles|Canada/Pacific',
    'America/Los_Angeles|Canada/Yukon',
    'America/Los_Angeles|Mexico/BajaNorte',
    'America/Los_Angeles|PST8PDT',
    'America/Los_Angeles|US/Pacific',
    'America/Los_Angeles|US/Pacific-New',
    'America/Managua|America/Belize',
    'America/Managua|America/Costa_Rica',
    'America/Managua|America/El_Salvador',
    'America/Managua|America/Guatemala',
    'America/Managua|America/Regina',
    'America/Managua|America/Swift_Current',
    'America/Managua|America/Tegucigalpa',
    'America/Managua|Canada/Saskatchewan',
    'America/Mexico_City|America/Bahia_Banderas',
    'America/Mexico_City|America/Merida',
    'America/Mexico_City|America/Monterrey',
    'America/Mexico_City|Mexico/General',
    'America/New_York|America/Detroit',
    'America/New_York|America/Fort_Wayne',
    'America/New_York|America/Grand_Turk',
    'America/New_York|America/Indiana/Indianapolis',
    'America/New_York|America/Indiana/Marengo',
    'America/New_York|America/Indiana/Petersburg',
    'America/New_York|America/Indiana/Vevay',
    'America/New_York|America/Indiana/Vincennes',
    'America/New_York|America/Indiana/Winamac',
    'America/New_York|America/Indianapolis',
    'America/New_York|America/Iqaluit',
    'America/New_York|America/Kentucky/Louisville',
    'America/New_York|America/Kentucky/Monticello',
    'America/New_York|America/Louisville',
    'America/New_York|America/Montreal',
    'America/New_York|America/Nassau',
    'America/New_York|America/Nipigon',
    'America/New_York|America/Pangnirtung',
    'America/New_York|America/Port-au-Prince',
    'America/New_York|America/Thunder_Bay',
    'America/New_York|America/Toronto',
    'America/New_York|Canada/Eastern',
    'America/New_York|EST5EDT',
    'America/New_York|US/East-Indiana',
    'America/New_York|US/Eastern',
    'America/New_York|US/Michigan',
    'America/Noronha|Atlantic/South_Georgia',
    'America/Noronha|Brazil/DeNoronha',
    'America/Noronha|Etc/GMT+2',
    'America/Panama|America/Atikokan',
    'America/Panama|America/Cancun',
    'America/Panama|America/Cayman',
    'America/Panama|America/Coral_Harbour',
    'America/Panama|America/Jamaica',
    'America/Panama|EST',
    'America/Panama|Jamaica',
    'America/Phoenix|America/Creston',
    'America/Phoenix|America/Dawson_Creek',
    'America/Phoenix|America/Fort_Nelson',
    'America/Phoenix|America/Hermosillo',
    'America/Phoenix|MST',
    'America/Phoenix|US/Arizona',
    'America/Santiago|Chile/Continental',
    'America/Santo_Domingo|America/Anguilla',
    'America/Santo_Domingo|America/Antigua',
    'America/Santo_Domingo|America/Aruba',
    'America/Santo_Domingo|America/Barbados',
    'America/Santo_Domingo|America/Blanc-Sablon',
    'America/Santo_Domingo|America/Curacao',
    'America/Santo_Domingo|America/Dominica',
    'America/Santo_Domingo|America/Grenada',
    'America/Santo_Domingo|America/Guadeloupe',
    'America/Santo_Domingo|America/Kralendijk',
    'America/Santo_Domingo|America/Lower_Princes',
    'America/Santo_Domingo|America/Marigot',
    'America/Santo_Domingo|America/Martinique',
    'America/Santo_Domingo|America/Montserrat',
    'America/Santo_Domingo|America/Port_of_Spain',
    'America/Santo_Domingo|America/Puerto_Rico',
    'America/Santo_Domingo|America/St_Barthelemy',
    'America/Santo_Domingo|America/St_Kitts',
    'America/Santo_Domingo|America/St_Lucia',
    'America/Santo_Domingo|America/St_Thomas',
    'America/Santo_Domingo|America/St_Vincent',
    'America/Santo_Domingo|America/Tortola',
    'America/Santo_Domingo|America/Virgin',
    'America/Sao_Paulo|Brazil/East',
    'America/St_Johns|Canada/Newfoundland',
    'Asia/Bangkok|Antarctica/Davis',
    'Asia/Bangkok|Asia/Barnaul',
    'Asia/Bangkok|Asia/Ho_Chi_Minh',
    'Asia/Bangkok|Asia/Hovd',
    'Asia/Bangkok|Asia/Krasnoyarsk',
    'Asia/Bangkok|Asia/Novokuznetsk',
    'Asia/Bangkok|Asia/Novosibirsk',
    'Asia/Bangkok|Asia/Phnom_Penh',
    'Asia/Bangkok|Asia/Saigon',
    'Asia/Bangkok|Asia/Tomsk',
    'Asia/Bangkok|Asia/Vientiane',
    'Asia/Bangkok|Etc/GMT-7',
    'Asia/Bangkok|Indian/Christmas',
    'Asia/Chita|Asia/Dili',
    'Asia/Chita|Asia/Khandyga',
    'Asia/Chita|Asia/Yakutsk',
    'Asia/Chita|Etc/GMT-9',
    'Asia/Chita|Pacific/Palau',
    'Asia/Dhaka|Antarctica/Vostok',
    'Asia/Dhaka|Asia/Almaty',
    'Asia/Dhaka|Asia/Bishkek',
    'Asia/Dhaka|Asia/Dacca',
    'Asia/Dhaka|Asia/Kashgar',
    'Asia/Dhaka|Asia/Omsk',
    'Asia/Dhaka|Asia/Qyzylorda',
    'Asia/Dhaka|Asia/Thimbu',
    'Asia/Dhaka|Asia/Thimphu',
    'Asia/Dhaka|Asia/Urumqi',
    'Asia/Dhaka|Etc/GMT-6',
    'Asia/Dhaka|Indian/Chagos',
    'Asia/Dubai|Asia/Baku',
    'Asia/Dubai|Asia/Muscat',
    'Asia/Dubai|Asia/Tbilisi',
    'Asia/Dubai|Asia/Yerevan',
    'Asia/Dubai|Etc/GMT-4',
    'Asia/Dubai|Europe/Astrakhan',
    'Asia/Dubai|Europe/Samara',
    'Asia/Dubai|Europe/Saratov',
    'Asia/Dubai|Europe/Ulyanovsk',
    'Asia/Dubai|Europe/Volgograd',
    'Asia/Dubai|Indian/Mahe',
    'Asia/Dubai|Indian/Mauritius',
    'Asia/Dubai|Indian/Reunion',
    'Asia/Gaza|Asia/Hebron',
    'Asia/Hong_Kong|Hongkong',
    'Asia/Jakarta|Asia/Pontianak',
    'Asia/Jerusalem|Asia/Tel_Aviv',
    'Asia/Jerusalem|Israel',
    'Asia/Kamchatka|Asia/Anadyr',
    'Asia/Kamchatka|Etc/GMT-12',
    'Asia/Kamchatka|Kwajalein',
    'Asia/Kamchatka|Pacific/Funafuti',
    'Asia/Kamchatka|Pacific/Kwajalein',
    'Asia/Kamchatka|Pacific/Majuro',
    'Asia/Kamchatka|Pacific/Nauru',
    'Asia/Kamchatka|Pacific/Tarawa',
    'Asia/Kamchatka|Pacific/Wake',
    'Asia/Kamchatka|Pacific/Wallis',
    'Asia/Kathmandu|Asia/Katmandu',
    'Asia/Kolkata|Asia/Calcutta',
    'Asia/Kuala_Lumpur|Antarctica/Casey',
    'Asia/Kuala_Lumpur|Asia/Brunei',
    'Asia/Kuala_Lumpur|Asia/Choibalsan',
    'Asia/Kuala_Lumpur|Asia/Irkutsk',
    'Asia/Kuala_Lumpur|Asia/Kuching',
    'Asia/Kuala_Lumpur|Asia/Singapore',
    'Asia/Kuala_Lumpur|Asia/Ulaanbaatar',
    'Asia/Kuala_Lumpur|Asia/Ulan_Bator',
    'Asia/Kuala_Lumpur|Etc/GMT-8',
    'Asia/Kuala_Lumpur|Singapore',
    'Asia/Makassar|Asia/Ujung_Pandang',
    'Asia/Rangoon|Asia/Yangon',
    'Asia/Rangoon|Indian/Cocos',
    'Asia/Sakhalin|Antarctica/Macquarie',
    'Asia/Sakhalin|Asia/Magadan',
    'Asia/Sakhalin|Asia/Srednekolymsk',
    'Asia/Sakhalin|Etc/GMT-11',
    'Asia/Sakhalin|Pacific/Bougainville',
    'Asia/Sakhalin|Pacific/Efate',
    'Asia/Sakhalin|Pacific/Guadalcanal',
    'Asia/Sakhalin|Pacific/Kosrae',
    'Asia/Sakhalin|Pacific/Norfolk',
    'Asia/Sakhalin|Pacific/Noumea',
    'Asia/Sakhalin|Pacific/Pohnpei',
    'Asia/Sakhalin|Pacific/Ponape',
    'Asia/Seoul|Asia/Pyongyang',
    'Asia/Seoul|ROK',
    'Asia/Shanghai|Asia/Chongqing',
    'Asia/Shanghai|Asia/Chungking',
    'Asia/Shanghai|Asia/Harbin',
    'Asia/Shanghai|Asia/Macao',
    'Asia/Shanghai|Asia/Macau',
    'Asia/Shanghai|Asia/Taipei',
    'Asia/Shanghai|PRC',
    'Asia/Shanghai|ROC',
    'Asia/Tashkent|Antarctica/Mawson',
    'Asia/Tashkent|Asia/Aqtau',
    'Asia/Tashkent|Asia/Aqtobe',
    'Asia/Tashkent|Asia/Ashgabat',
    'Asia/Tashkent|Asia/Ashkhabad',
    'Asia/Tashkent|Asia/Atyrau',
    'Asia/Tashkent|Asia/Dushanbe',
    'Asia/Tashkent|Asia/Oral',
    'Asia/Tashkent|Asia/Samarkand',
    'Asia/Tashkent|Asia/Yekaterinburg',
    'Asia/Tashkent|Etc/GMT-5',
    'Asia/Tashkent|Indian/Kerguelen',
    'Asia/Tashkent|Indian/Maldives',
    'Asia/Tehran|Iran',
    'Asia/Tokyo|Japan',
    'Asia/Vladivostok|Antarctica/DumontDUrville',
    'Asia/Vladivostok|Asia/Ust-Nera',
    'Asia/Vladivostok|Etc/GMT-10',
    'Asia/Vladivostok|Pacific/Chuuk',
    'Asia/Vladivostok|Pacific/Port_Moresby',
    'Asia/Vladivostok|Pacific/Truk',
    'Asia/Vladivostok|Pacific/Yap',
    'Atlantic/Azores|America/Scoresbysund',
    'Atlantic/Cape_Verde|Etc/GMT+1',
    'Australia/Adelaide|Australia/Broken_Hill',
    'Australia/Adelaide|Australia/South',
    'Australia/Adelaide|Australia/Yancowinna',
    'Australia/Brisbane|Australia/Lindeman',
    'Australia/Brisbane|Australia/Queensland',
    'Australia/Darwin|Australia/North',
    'Australia/Lord_Howe|Australia/LHI',
    'Australia/Perth|Australia/West',
    'Australia/Sydney|Australia/ACT',
    'Australia/Sydney|Australia/Canberra',
    'Australia/Sydney|Australia/Currie',
    'Australia/Sydney|Australia/Hobart',
    'Australia/Sydney|Australia/Melbourne',
    'Australia/Sydney|Australia/NSW',
    'Australia/Sydney|Australia/Tasmania',
    'Australia/Sydney|Australia/Victoria',
    'Etc/UCT|UCT',
    'Etc/UTC|Etc/Universal',
    'Etc/UTC|Etc/Zulu',
    'Etc/UTC|UTC',
    'Etc/UTC|Universal',
    'Etc/UTC|Zulu',
    'Europe/Athens|Asia/Famagusta',
    'Europe/Athens|Asia/Nicosia',
    'Europe/Athens|EET',
    'Europe/Athens|Europe/Bucharest',
    'Europe/Athens|Europe/Helsinki',
    'Europe/Athens|Europe/Kiev',
    'Europe/Athens|Europe/Mariehamn',
    'Europe/Athens|Europe/Nicosia',
    'Europe/Athens|Europe/Riga',
    'Europe/Athens|Europe/Sofia',
    'Europe/Athens|Europe/Tallinn',
    'Europe/Athens|Europe/Uzhgorod',
    'Europe/Athens|Europe/Vilnius',
    'Europe/Athens|Europe/Zaporozhye',
    'Europe/Chisinau|Europe/Tiraspol',
    'Europe/Dublin|Eire',
    'Europe/Istanbul|Antarctica/Syowa',
    'Europe/Istanbul|Asia/Aden',
    'Europe/Istanbul|Asia/Baghdad',
    'Europe/Istanbul|Asia/Bahrain',
    'Europe/Istanbul|Asia/Istanbul',
    'Europe/Istanbul|Asia/Kuwait',
    'Europe/Istanbul|Asia/Qatar',
    'Europe/Istanbul|Asia/Riyadh',
    'Europe/Istanbul|Etc/GMT-3',
    'Europe/Istanbul|Europe/Kirov',
    'Europe/Istanbul|Europe/Minsk',
    'Europe/Istanbul|Turkey',
    'Europe/Lisbon|Atlantic/Canary',
    'Europe/Lisbon|Atlantic/Faeroe',
    'Europe/Lisbon|Atlantic/Faroe',
    'Europe/Lisbon|Atlantic/Madeira',
    'Europe/Lisbon|Portugal',
    'Europe/Lisbon|WET',
    'Europe/London|Europe/Belfast',
    'Europe/London|Europe/Guernsey',
    'Europe/London|Europe/Isle_of_Man',
    'Europe/London|Europe/Jersey',
    'Europe/London|GB',
    'Europe/London|GB-Eire',
    'Europe/Moscow|Europe/Simferopol',
    'Europe/Moscow|W-SU',
    'Europe/Paris|Africa/Ceuta',
    'Europe/Paris|Arctic/Longyearbyen',
    'Europe/Paris|Atlantic/Jan_Mayen',
    'Europe/Paris|CET',
    'Europe/Paris|Europe/Amsterdam',
    'Europe/Paris|Europe/Andorra',
    'Europe/Paris|Europe/Belgrade',
    'Europe/Paris|Europe/Berlin',
    'Europe/Paris|Europe/Bratislava',
    'Europe/Paris|Europe/Brussels',
    'Europe/Paris|Europe/Budapest',
    'Europe/Paris|Europe/Busingen',
    'Europe/Paris|Europe/Copenhagen',
    'Europe/Paris|Europe/Gibraltar',
    'Europe/Paris|Europe/Ljubljana',
    'Europe/Paris|Europe/Luxembourg',
    'Europe/Paris|Europe/Madrid',
    'Europe/Paris|Europe/Malta',
    'Europe/Paris|Europe/Monaco',
    'Europe/Paris|Europe/Oslo',
    'Europe/Paris|Europe/Podgorica',
    'Europe/Paris|Europe/Prague',
    'Europe/Paris|Europe/Rome',
    'Europe/Paris|Europe/San_Marino',
    'Europe/Paris|Europe/Sarajevo',
    'Europe/Paris|Europe/Skopje',
    'Europe/Paris|Europe/Stockholm',
    'Europe/Paris|Europe/Tirane',
    'Europe/Paris|Europe/Vaduz',
    'Europe/Paris|Europe/Vatican',
    'Europe/Paris|Europe/Vienna',
    'Europe/Paris|Europe/Warsaw',
    'Europe/Paris|Europe/Zagreb',
    'Europe/Paris|Europe/Zurich',
    'Europe/Paris|Poland',
    'Pacific/Auckland|Antarctica/McMurdo',
    'Pacific/Auckland|Antarctica/South_Pole',
    'Pacific/Auckland|NZ',
    'Pacific/Chatham|NZ-CHAT',
    'Pacific/Easter|Chile/EasterIsland',
    'Pacific/Galapagos|Etc/GMT+6',
    'Pacific/Gambier|Etc/GMT+9',
    'Pacific/Guam|Pacific/Saipan',
    'Pacific/Honolulu|HST',
    'Pacific/Honolulu|Pacific/Johnston',
    'Pacific/Honolulu|US/Hawaii',
    'Pacific/Kiritimati|Etc/GMT-14',
    'Pacific/Niue|Etc/GMT+11',
    'Pacific/Pago_Pago|Pacific/Midway',
    'Pacific/Pago_Pago|Pacific/Samoa',
    'Pacific/Pago_Pago|US/Samoa',
    'Pacific/Pitcairn|Etc/GMT+8',
    'Pacific/Tahiti|Etc/GMT+10',
    'Pacific/Tahiti|Pacific/Rarotonga',
    'Pacific/Tongatapu|Etc/GMT-13',
    'Pacific/Tongatapu|Pacific/Enderbury',
    'Pacific/Tongatapu|Pacific/Fakaofo',
  ],
};

populateTimeZones(data);

exports.listTimeZones = listTimeZones;
exports.findTimeZone = findTimeZone;
exports.getUTCOffset = getUTCOffset;
exports.getZonedTime = getZonedTime;
exports.getUnixTime = getUnixTime;
exports.setTimeZone = setTimeZone;
exports.convertTimeToDate = convertTimeToDate;
exports.convertDateToTime = convertDateToTime;
