import * as React from 'react';
import { useLocale } from '../../common/hooks/useLocale';
import { useGlobalVariables } from '../../common/hooks/useGlobalVariables';
import { interpolateGlobalVariables } from '../../common/util/interpolateGlobalVariables';

interface InterpolatedTextProps {
  text?: string;
}

export const InterpolatedText: React.FC<InterpolatedTextProps> = ({ text }) => {
  if (!text) {
    return null;
  }
  const memoizedText = useInterpolatedText(text);

  if (!memoizedText) return null;

  return <>{memoizedText}</>;
};

export function useInterpolatedText(text: string) {
  const locale = useLocale();
  const globalVariables = useGlobalVariables();
  const memoizedText = React.useMemo(
    () => (!text ? null : interpolateGlobalVariables(text, locale, globalVariables.json_values)),
    [locale]
  );

  return memoizedText;
}
