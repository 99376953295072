import React from 'react';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { Resource, resourceCTAToGrobalStringsKeysMapping } from '../interfaces/resource.interface';
import get from 'lodash/get';
import { A, Span } from '../../common/Elements';
import { Icon } from '../../common/Icon';
import { TextVariantMap } from '../../common/Text/Text.interface';

interface ResourceCardCTAProps {
  resource: Resource;
  ctaMethod?: (streamId: string) => void;
}

export const ResourceCardCTA: React.FC<ResourceCardCTAProps> = ({ resource, ctaMethod }) => {
  const staticStrings = useStaticStrings();
  if (resource.resourceReference || resource.externalLinkUrl) {
    return (
      <A
        color="blue1"
        {...TextVariantMap.learnmore}
        hovered={{ color: 'bluehover' }}
        href={
          resource.externalLinkUrl ? resource.externalLinkUrl : resource.callToActionDestination
        }
      >
        <Span className="learn-more" {...TextVariantMap.learnmore} marginRight={1}>
          {staticStrings.json_values[resourceCTAToGrobalStringsKeysMapping[resource.callToAction]]}
        </Span>
      </A>
    );
  }
  if (resource.resourceMedia && resource.resourceMedia.file) {
    return (
      <A
        color="blue1"
        {...TextVariantMap.learnmore}
        hovered={{ color: 'bluehover' }}
        href={get(resource, 'resourceMedia.file.publicURL')}
        download
      >
        <Span className="learn-more" {...TextVariantMap.learnmore} marginRight={1}>
          {resource.callToAction}{' '}
        </Span>
      </A>
    );
  }
  if (resource.resourceStreamId) {
    return (
      <A
        color="blue1"
        className="pointer"
        {...TextVariantMap.learnmore}
        hovered={{ color: 'bluehover' }}
        onClick={e => {
          ctaMethod && ctaMethod(resource.resourceStreamId || '');
        }}
      >
        <Span className="learn-more" {...TextVariantMap.learnmore} marginRight={1}>
          {resource.callToAction}{' '}
        </Span>
      </A>
    );
  }
  return null;
};
